import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

export default ({routes, user}) => (
  <Navbar bg="dark" variant="dark">
    <Nav className="mr-auto">
      {Object.entries(routes).map(([key, {label, required_permissions=[]}]) => {
        return required_permissions.every(perm => user.permissions.includes(perm)) && (
          <Nav.Item key={key}>
            <Nav.Link as={Link} to={`/app/${key}`} key={key}>{label}</Nav.Link>
          </Nav.Item>
        )
      })}
    </Nav>
    <Nav>
      <Nav.Link as={Link} onClick={() => fetch('/logout')
        .then(() => {window.location = '/'})
      } to="/logout">Logout</Nav.Link>
    </Nav>
  </Navbar>
)
