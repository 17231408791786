import { useRef, useCallback, useEffect } from 'react';

const portal_div = document.getElementById('portal');

const useClickOutside = onClickOutside => {
  const wrappedRef = useRef();
  const root_element = document.body;
  const clickOutsideHandler = useCallback(evt => {
    if(wrappedRef.current && !wrappedRef.current.contains(evt.target) && !portal_div.contains(evt.target)){
      onClickOutside && onClickOutside(evt);
    }
  }, [onClickOutside]);
  useEffect(() => {
    root_element.addEventListener('click', clickOutsideHandler);
    return () => {root_element.removeEventListener('click', clickOutsideHandler)};
  },[clickOutsideHandler, root_element]);
  return wrappedRef;
}

export default useClickOutside;
