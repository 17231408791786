import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
}                                     from 'react';
import Select                         from 'react-select';
import BetaFeatures                   from './components/BetaFeatures';
import LoginAs                        from './components/LoginAs';
import PhoneProvision                 from './components/PhoneProvision';
import PhoneMover                     from './components/PhoneMover';
import WhatsappPhoneEnable            from './components/WhatsappPhoneEnable';
import ConferenceList                 from './components/ConferenceList';
import MessagingPricingEditor         from './components/MessagingPricingEditor';
import { useQueryParam, StringParam } from 'use-query-params';

const sortDealers = dealers => dealers
  .sort((d1, d2) => (d1.friendly_name || d1.domain || '').localeCompare(d2.friendly_name || d2.domain || ''));

const loadDefinitions = async () => {
  return await fetch('/api/mapi/definitions/v1')
    .then(res => res.ok ? res.json() : res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`)));
}

const loadAllDealers = async () => {
  const q = {active: true};
  const project = '_id=1,friendly_name=1,domain=1,twilio_subaccount_credentials.sid=1';
  let has_more = false;
  let skip = 0;
  let dealers = [];
  do {
    const [next_dealers, next_has_more] = await fetch(`/api/mapi/dealers?q=${encodeURIComponent(JSON.stringify(q))}&skip=${skip}&project=${encodeURIComponent(project)}`)
      .then(res => res.ok ? (
        Promise.all([res.json(), res.headers.get('mapp-has-more')])
      ) : (
        res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`))
      ));
    skip += 50;
    has_more = next_has_more === 'true';
    dealers = dealers.concat(next_dealers);
  } while(has_more);
  return dealers
};

export default ({ user }) => {
  const [ dealers, setDealers ] = useState([]);
  const dealerSelectorRef = useRef(null);

  const [ definitions, setDefinitions ] = useState();
  const [ selected_dealer_id, setSelectedDealerId ] = useQueryParam('dealer_id', StringParam);

  const selected_dealer = dealers.find(d => d._id === selected_dealer_id);

  const dealer_options = useMemo(() => dealers.map(d => ({
    label: `${d.friendly_name || d.domain} (${d._id})`,
    value: d,
  })),[dealers]);

  useEffect(() => {
    loadDefinitions().then(setDefinitions)
  }, [setDefinitions]);

  useEffect(() => {
    if (dealerSelectorRef.current)
      dealerSelectorRef.current.focus();
    loadAllDealers().then(d => setDealers(sortDealers(d)));
  }, []);

  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  }}>
    <div style={{
      margin: '1em',
      display: 'flex',
      alignItems: 'center',
      gap: '1em',
    }}>
      <Select
        styles={{
          container: base => ({ ...base, flex: 0.45 }),
        }}
        isClearable
        onChange={e => setSelectedDealerId(e ? e.value?._id : null)}
        options={dealer_options}
        ref={dealerSelectorRef}
        value={dealer_options.find(d => d?.value?._id === selected_dealer_id)}
        filterOption={(candidate, input) => {
          // Match by friendly_name, domain, id or twilio_subaccount_credentials.sid
          if(!input) return true;
          const dealer = candidate?.value;
          const search = input.toLowerCase();
          return (dealer?.friendly_name || '').toLowerCase().includes(search)
            || (dealer?.domain || '').toLowerCase().includes(search)
            || (dealer?._id || '').toLowerCase().includes(search)
            || (dealer?.twilio_subaccount_credentials?.sid || '').toLowerCase().includes(search);
        }}
      />
      {selected_dealer_id && <BetaFeatures
        dealer_id={selected_dealer_id}
        definitions={definitions}
      />}
    </div>
    <div style={{
      margin: '1em',
      display: 'flex',
      alignItems: 'center',
      flex: 1,
    }}>
      {selected_dealer_id && (
        <div key={selected_dealer_id} style={{
          display: 'flex',
          height: '100%',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}>
          {user.permissions.includes('LoginAs') &&
            <LoginAs dealer_id={selected_dealer_id} />
          }
          {user.permissions.includes('Twilio') &&
            <>
              <h4
                style={{
                  margin: '0.5em 0 ',
                }}>
                ID de Twilio: {selected_dealer?.twilio_subaccount_credentials.sid}
              </h4>
              <h2>Provisionar teléfonos de Twilio</h2>
              <PhoneProvision dealer_id={selected_dealer_id} />
              <h2>Mover número a esta cuenta</h2>
              <PhoneMover dealer_id={selected_dealer_id} />
              <h2>Activar número de whatsapp</h2>
              <WhatsappPhoneEnable dealer_id={selected_dealer_id} />
              <h2>Llamadas en curso</h2>
              <ConferenceList dealer_id={selected_dealer_id} />
            </>
          }
          {user.permissions.includes('Billing') && (
            <MessagingPricingEditor dealer_id={selected_dealer_id} />
          )}
          <div style={{ flex: 1 }} />
        </div>
      )}
    </div>
  </div>
}
