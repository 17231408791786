import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import moment    from 'moment';
moment.locale('es');

import Select from 'react-select';
import Async  from 'react-select/async';

const sortDealers = dealers => dealers
  .sort((d1, d2) => (d1.friendly_name || d1.domain || '').localeCompare(d2.friendly_name || d2.domain || ''));


const loadAllDealers = async () => {
  const q = {active: true};
  let has_more = false;
  let skip = 0;
  let dealers = [];
  do {
    const [next_dealers, next_has_more] = await fetch(`/api/mapi/dealers?q=${encodeURIComponent(JSON.stringify(q))}&skip=${skip}`)
      .then(res => res.ok ? (
        Promise.all([res.json(), res.headers.get('mapp-has-more')])
      ) : (
        res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`))
      ));
    skip += 50;
    has_more = next_has_more === 'true';
    dealers = dealers.concat(next_dealers);
  } while(has_more);
  return dealers
};

const sortUsers = users => users
  .sort((u1, u2) => `${u1.first_name}${u1.last_name}`.localeCompare(`${u2.first_name}${u2.last_name}`));


export default () => {
  const [ selected_user, setSelectedUser ] = useState('');
  const [ selected_dealer, setSelectedDealer ] = useState('');
  const [ users, setUsers ] = useState({options: [], users: []});
  const [ dealers, setDealers ] = useState([]);
  const [ sync_response, setSyncResponse ] = useState('');
  const [ loading, setLoading ] = useState('');
  const dealerSelectorRef = useRef(null);

  useEffect(() => {
    if (dealerSelectorRef.current)
      dealerSelectorRef.current.focus();
    loadAllDealers().then(d => setDealers(sortDealers(d)));
  }, []);

  const dealer_options = useMemo(() => dealers.map(d => ({
    label: `${d.friendly_name || d.domain} (${d._id})`,
    value: d._id,
  })),[dealers]);

  const user = users.users.find(u => u._id === selected_user)
  const full_name = user && `${user?.first_name} ${user?.last_name}`
  const last_successful_renovation = user?.email_settings?.incoming?.last_successful_renovation;
  const sync_status = user?.email_settings?.incoming?.sync_status;

  const forceSync = (dealer_id, user_id, since_date) => fetch(`/api/mappex/services/mail/incoming/dealers/${dealer_id}/syncUser/${user_id}`, {
    method:'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      full_sync: true,
      full_sync_since: since_date,
      partial_sync: true,
      ignore_tag: true,
    }),
  }).then(r => r.ok && r.json() || r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`)))
    .then(() => {setLoading(false); setSyncResponse('Petición realizada correctamente. El proceso de sincronización puede tardar hasta 20 min.');})
    .catch(err => {setLoading(false); setSyncResponse(`Error: ${err}`);})

  const loadUsers = useCallback(str => {
    const q = {active: true, $or: [{first_name: {$regex: str, $options: 'i'}}, {last_name: {$regex: str, $options: 'i'}}]};
    return fetch(`/api/mapi/dealers/${selected_dealer}/users?q=${encodeURIComponent(JSON.stringify(q))}`)
      .then(res => res.ok ? res.json() : res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`)))
      .then(res => ({
        options: sortUsers(res).map(d => ({label: `${d.role === 'dealer_admin' ? '* ' : ''}${d.first_name} ${d.last_name}`, value: d._id})),
        users: res,
      }));
  }, [ selected_dealer ]);

  return (
    <div>
      <div>Permite forzar la sincronización de correo de un usuario desde la fecha de última sincronización.</div>
      <Select
        styles={{
          container: base => ({...base, width: '30em'}),
        }}
        isClearable
        onChange={e => setSelectedDealer(e ? e.value : null)}
        options={dealer_options}
        ref={dealerSelectorRef}
        value={dealer_options.find(d => d.value === selected_dealer)}
        placeholder='Selecciona el dealer'
      />
      {selected_dealer && (<div>
        <Async
          styles={{
            container: base => ({...base, width: '30em'}),
          }}
          onChange={e => { setSelectedUser(e?.value || '') }}
          key={selected_dealer}
          loadOptions={str => loadUsers(str).then(res => {setUsers(res); return res.options})}
          defaultOptions
          isClearable
          value={users.options.find(u => u.value === selected_user)}
          placeholder='Selecciona el usuario'
        />
      </div>)}
      {selected_dealer && user && (<div style={{width: '60em'}}>
        <table className='table'>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Última sincronización</th>
              <th>Sync status</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {(
              <tr key={selected_user._id}>
                <td>{full_name}</td>
                <td>{last_successful_renovation && moment(last_successful_renovation).format('LLL')}</td>
                <td>{sync_status}</td>
                <td>
                  <button
                    disabled={!last_successful_renovation || loading}
                    onClick={() =>{
                      setLoading(true);
                      setSyncResponse('');
                      forceSync(selected_dealer, selected_user, last_successful_renovation)
                    }}
                  >
                    Forzar sincro
                  </button>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {sync_response}
      </div>)}
    </div>)
}
