import React, {
  useState,
}                  from 'react';
import AsyncButton from '../AsyncButton';

const processFetchResponse = u => u.ok ? u.json() : u.text().then(t => Promise.reject(`Unexpected error code ${u.status} on url: ${u.url}: ${t}`));

const CancellationConfirm = ({
  subscription,
  close,
  setSubscriptions,
}) => {
  const [ invoice_now, setInvoiceNow ] = useState(false);
  const cancelSubscription = () => {
    return fetch(`/api/billing/subscriptions/${subscription._id}/cancel`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ invoice_now }),
    })
      .then(processFetchResponse)
      .then(s => setSubscriptions(subs => ({...subs, [s._id]: s})))
      .then(close)
  }
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '1em',
    }}>
      ¿Estás seguro de que quieres cancelar la suscripción?
      <div style={{flex: 1}}/>
      {subscription.status === 'active' && <div
        style={{display: 'flex', alignItems: 'center', gap: '0.5em', cursor: 'pointer'}}
        onClick={() => setInvoiceNow(i => !i)}
      >
        <input type='checkbox' readOnly checked={invoice_now} />
        Emitir factura ahora con la cantidad pendiente da la subscripción
      </div>}
      <AsyncButton onClick={cancelSubscription}>Cancelar</AsyncButton>
    </div>
  );
}

export default CancellationConfirm;
