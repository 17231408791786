import React, {
  useState,
} from 'react';

const WhatsappPhoneEnable = ({ dealer_id }) => {
  const [ number, setNumber ] = useState();
  const provisionNumber = async () => {

    // MX whatsapp numbers start with +521 because of meta issues with old prefixes...
    // We have to take this into account when provisioning the number
    const is_mexican_number = number.startsWith('+52');

    // Find the number thats already in the database (with the +52 prefix)
    const parsed_number = number.replace('+521', '+52');
    const query = { number: parsed_number };
    const [api_number] = await fetch(`/api/mapi/dealers/${dealer_id}/phones?q=${encodeURIComponent(JSON.stringify(query))}`)
      .then(r => r.ok && r.json() || r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`)))

    // If its a whatsapp number and its not in the database, create it with the +521 prefix
    if (is_mexican_number && api_number) {
      const final_number = number.startsWith('+521') ? number : number.replace('+52', '+521');
      await fetch(`/api/mapi/dealers/${dealer_id}/phones`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...api_number,
          whatsapp_enabled: true,
          call_enabled: false,
          sms_enabled: false,
          sms_routing: null,
          dial_flow: null,
          label: `${api_number.label} (Whatsapp)`,
          number: final_number,
          active_services: ['whatsapp'],
          _id: undefined,
        }),
      }).then(r => r.ok && r.json() || r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`)));
      return alert('Número de Whatsapp activado con éxito');
    }

    if (api_number) {
      await fetch(`/api/mapi/dealers/${dealer_id}/phones/${api_number._id}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify([{ op: 'replace', path: '/whatsapp_enabled', value: true }]),
      })
        .then(r => r.ok && r.json() || r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`)))
      return alert('Número activado con éxito');
    }
  }
  return (
    <div>
      <div style={{
        display: 'grid',
        gridGap: '0.5em 1em',
        gridTemplateColumns: 'auto 1fr',
        alignItems: 'center',
      }}>
        <span>Número</span>
        <input value={number} onChange={e => setNumber(e.target.value)} />
      </div>
      <div>
        <b>Activa solo el número si se ha completado la configuración manual del número desde Twilio</b>
      </div>
      <button
        onClick={provisionNumber}
        disabled={!number}
      >
        Activar
      </button>
    </div>
  );
}

export default WhatsappPhoneEnable;
