import React from 'react';

const LoginAs = ({ user_id }) => {
  const doLoginAs = user_id => {
    fetch(`/api/tasator/login_as?user_id=${user_id}`)
      .then(res => res.ok ? res.json() : res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`)))
      .then(({token})=> {
        window.open(`${process.env.REACT_APP_TASATOR_BASE_URL}login_as_request?token=${token}`)
      })
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    }}>
      {user_id && (
        <button style={{padding: '1em'}} onClick={() => doLoginAs(user_id)}>
          Entrar
        </button>
      )}
    </div>
  );
}

export default LoginAs;
