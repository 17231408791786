import React, {
  useState,
  useEffect,
} from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { Card, Spinner } from 'react-bootstrap';

moment.locale('es');

const sortDealers = dealers => dealers
  .sort((d1, d2) => (d1.friendly_name || d1.domain || '').localeCompare(d2.friendly_name || d2.domain || ''));


const loadAllDealers = async () => {
  const q = {active: true};
  let has_more = false;
  let skip = 0;
  let dealers = [];
  do {
    const [next_dealers, next_has_more] = await fetch(`/api/mapi/dealers?q=${encodeURIComponent(JSON.stringify(q))}&skip=${skip}`)
      .then(res => res.ok ? (
        Promise.all([res.json(), res.headers.get('mapp-has-more')])
      ) : (
        res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`))
      ));
    skip += 50;
    has_more = next_has_more === 'true';
    dealers = dealers.concat(next_dealers);
  } while(has_more);
  return dealers
};

const getAllSubscriptions = () => fetch(`/api/subscription_info`)
  .then(r => r.ok ? r.json() : r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`)))
  .then(r => r.reduce((p,c) => ({...p, [c.customer]: c}), {}))

const renderDiscount = discount => {
  if(!discount) return '-';
  return (
    <span style={{display: 'flex', alignItems: 'center', justifyContent:
      'center'}}>
      {discount.coupon.percent_off}%
      {discount.end && (
        <span style={{
          fontSize: '0.8em',
          color: moment(discount.end*1000).isBefore(moment().add(1, 'month')) ? 'red': 'black',
        }}>
          &nbsp;({moment(discount.end*1000).locale('es').fromNow(true)})
        </span>
      )}
      {discount.coupon.duration === 'once' && (
        <span style={{
          fontSize: '0.8em',
          color: 'red',
        }}>
          &nbsp;(Una sola vez)
        </span>
      )}
    </span>
  )
}

const plans = ['base', 'voice', 'gsm', 'messaging'];

const renderSignedDiff = (percent, number, options) => (
  <span style={{
    color: number > 0 ? 'green' : 'red',
    fontWeight: percent < 20 ? 'normal' : 'bold',
    display: percent > 5 ? 'inherit' : 'none',
    fontSize: '0.8em',
  }}>
    &nbsp;({(number && number > 0 ? '+' : '').concat(Number(number).toLocaleString('es', options))})
  </span>
)

const renderGeneralInvoiceStats = (stats, percent) => {
  if (!stats) return '-';

  const current = Math.round((stats.current_invoicing || 0)/100);
  const prev = Math.round((stats.last_invoicing || 0)/100);
  return (<>
      <span>{moment.months()[moment().get('month')] + ": " + current.toLocaleString('es', {
        style: 'currency',
        currency: 'eur',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}</span>
      <br />
      <span>Δ vs {moment.months()[moment().subtract(1, 'month').get('month')] + ": "}{renderSignedDiff(percent || (current - prev) * 100 / current,
        current - prev, {
          style: 'currency',
          currency: 'eur',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
      })}</span>
  </>)
}

const renderInvoiceStats = (stats, percent) => {
  if(!stats) return '-';
  if(stats.invoice_status === 'trialing') return (
    <span style={{color: '#8e610f'}}>
      Trial ({moment(stats.trial_ends_at*1000).fromNow(true)})
    </span>
  );
  if(stats.invoice_status && stats.invoice_status !== 'active'){
    return (
      <span style={{color: '#8e610f'}}>
        No pagado
      </span>
    );
  }
  const current = Math.round((stats.current_invoicing || 0)/100);
  const prev = Math.round((stats.last_invoicing || 0)/100);
  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <span>{current.toLocaleString('es', {
          style: 'currency',
          currency: 'eur',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
      })}</span>
      {renderSignedDiff(percent || (current - prev) * 100 / current, current - prev, {
        style: 'currency',
        currency: 'eur',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}
    </div>
  );
}

const renderPlanCards = (plan, stats) => {
  if(!stats) return '-';
  const current = stats.current_plan_counts[plan] || 0;
  const prev = stats.last_plan_counts[plan] || 0;

  return (<Card className={'col-xs-6'} style={{ marginLeft: '.5rem', marginBottom: '.5rem' }} key={plan}>
    <Card.Header>Plan {plan}</Card.Header>
    <Card.Body>
      <Card.Text>
        {(plan === 'base' ? <span>{current} {renderSignedDiff((current - prev) * 100 / current, current - prev)}</span>
          : <span>{Math.floor(current * 100 / stats.current_plan_counts['base'])}%</span>)}
      </Card.Text>
    </Card.Body>
  </Card>)
}

const renderClientsDiff = clients => {
  const current = clients.length;
  const prev = clients.filter(val => moment(val.trial_ends_at * 1000).isSame(moment().subtract(1, 'month'), 'month') || moment(val.created *
    1000).isSame(moment().subtract(1, 'month'), 'month')).length;
  const diff = current - (current - prev);
  return <>
    <span>{moment.months()[moment().get('month')] + ": " + current}</span>
    <br />
    <span>Δ vs {moment.months()[moment().subtract(1, 'month').get('month')]}: {renderSignedDiff(100, diff)}</span>
  </>
}

const renderPlanStats = (plan, stats) => {
  if(!stats) return '-';
  const current = stats.current_plan_counts[plan] || 0;
  const prev = stats.last_plan_counts[plan] || 0;
  return (<div style={{display: 'flex', alignItems: 'center', justifyContent:
  'center'}}>
    <span>{plan === 'base' ? <>{current} {renderSignedDiff((current - prev) * 100 / current, current - prev)}</> : Math.floor(current * 100 / stats.current_plan_counts['base'] || 0) + '%'}</span>
  </div>)
}

const mergeCounts = (prev_counts, new_counts) => {
  const keys = [...new Set([...Object.keys(prev_counts), ...Object.keys(new_counts)])];
  return keys.reduce((p,c) => ({...p, [c]: (prev_counts[c] || 0) + (new_counts[c] || 0)}),{})
}

const getTrials = subscriptions => Object.keys(subscriptions).map(key => subscriptions[key]).filter(subscription => subscription.invoice_status === 'trialing');
const getCurrentTrials = subscriptions => getTrials(subscriptions).filter(trial => moment(trial.trial_ends_at * 1000).isSame(moment(), 'month'));

const getPastDue = subscriptions => Object.keys(subscriptions).map(key => subscriptions[key]).filter(subscription => subscription.invoice_status === 'past_due');

export default () => {
  const [ dealers, setDealers ] = useState([]);
  const [ metrics, setMetrics ] = useState([]);
  const [ subscriptions, setSubscriptions ] = useState([]);
  const [ total_stats, setTotalStats ] = useState(null);
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    loadAllDealers().then(d => setDealers(sortDealers(d)));
  }, []);

  useEffect(() => {
    fetch('/api/usage-metrics').then(r => r.json()).then(json => setMetrics(json));
  }, []);

  useEffect(() => {
    let retries = 100;

    const action = () => getAllSubscriptions().then(d => {
      setSubscriptions(d);
      setTotalStats(Object.values(d).reduce((p,stats) => {
        const is_active = stats.invoice_status === 'active';
        return {
          current_plan_counts: mergeCounts(p.current_plan_counts, stats.current_plan_counts),
          last_plan_counts: mergeCounts(p.last_plan_counts, stats.last_plan_counts),
          next_invoice_amount: !is_active ? p.next_invoice_amount : p.next_invoice_amount + stats.next_invoice_amount,
          last_invoice_amount: !is_active ? p.last_invoice_amount : p.last_invoice_amount + stats.last_invoice_amount,
          current_invoicing: !is_active ? p.current_invoicing : p.current_invoicing + stats.current_invoicing,
          last_invoicing: !is_active ? p.last_invoicing : p.last_invoicing + stats.last_invoicing,
        };
      }, {
        current_plan_counts: [],
        last_plan_counts: [],
        next_invoice_amount: 0,
        last_invoice_amount: 0,
        current_invoicing: 0,
        last_invoicing: 0,
      }));
      setLoading(false);
    }).catch(() => {
      if (retries != 0)
        setTimeout(action, 3500);
      retries--;
    });

    action();
  }, []);
  return (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  }}>
    <div style={{
      display: 'flex',
      marginTop: '2%',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}>
      <Card style={{ width: '100%', marginLeft: '2%' }}>
        <Card.Header>Clientes</Card.Header>
        <Card.Body>
          <Card.Text>
            {subscriptions ? renderClientsDiff(Object.keys(subscriptions).filter(key => subscriptions[key].invoice_status !== 'trialing').map(key => subscriptions[key])) : 0}
          </Card.Text>
        </Card.Body>
      </Card>
      <Card style={{ width: '100%', marginLeft: '2%' }}>
        <Card.Header>Facturación prevista</Card.Header>
        <Card.Body>
          <Card.Text>{renderGeneralInvoiceStats(total_stats, 100)/* force render with
            100 percent */}</Card.Text>
        </Card.Body>
      </Card>
      <Card style={{ width: '100%', marginLeft: '2%' }}>
        <Card.Header>Trials Activos</Card.Header>
        <Card.Body>
          <Card.Text>
            {subscriptions ? <>
              <span>Vencen {moment.months()[new Date().getMonth()]}: {getCurrentTrials(subscriptions).length} ({
                Math.floor(getCurrentTrials(subscriptions)
                  .reduce((a, b) => a + b.current_invoicing, 0)
                  / 100)
                .toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })
              })</span>
              <br />
              <span>Resto: {getTrials(subscriptions).length -
                getCurrentTrials(subscriptions).length}</span>
            </> : 0}
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
    <div className={'row'} style={{
      display: 'flex',
      marginTop: '2%',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}>
      {plans.map(p => renderPlanCards(p, total_stats))}
    </div>
    {loading &&
      <div style={{
        display: 'flex',
        marginTop: '2%',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
        <h3>Cargando datos... &nbsp;</h3>
        <Spinner animation="border" />
      </div>
    }
    <div style={{padding: '0em 1em', marginTop: '4%'}}>
      <div style={{marginBottom: '1%'}}>
        <h3>Trials Activos</h3>
      </div>
      <table>
        <thead>
          <tr>
            <th>Nombre cliente</th>
            <th>Usuarios</th>
            <th>Vencimiento</th>
            <th>Facturacion</th>
          </tr>
        </thead>
        <tbody>
          {getTrials(subscriptions).concat(getPastDue(subscriptions)).map(trial => dealers.find(dealer => dealer.billing.customer_id === trial.customer) ? {...dealers.find(dealer => dealer.billing.customer_id === trial.customer), ...trial} : undefined)
            .filter(a => a)
            .sort((a, b) => a.trial_ends_at - b.trial_ends_at)
            .map((trial, i) => {
              const endsDate = moment(trial.trial_ends_at * 1000);
              const currentDate = moment(new Date());
              return (
                <tr key={i} style={{
                  fontWeight: endsDate.isSame(currentDate, 'month') ? 'bold' :
                  'normal',
                  color: trial.invoice_status === 'past_due' ? 'red' : 'inherit',
                }}>
                  <td>{trial.friendly_name || trial.domain}</td>
                  <td>{trial.current_plan_counts.base}</td>
                  <td>{`${moment.months()[endsDate.get('month')]} (${new Date(trial.trial_ends_at * 1000).toLocaleString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' })})`}</td>
                  <td>{Math.floor(trial.current_invoicing / 100) + ' €'}</td>
		</tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
    <div style={{padding: '0em 1em', marginTop: '2%'}}>
      <table style={{width: '100%'}}>
        <thead>
          <tr>
            <th>Nombre</th>
            {plans.map((p, i) => (<th key={i}>Plan {p}</th>))}
            <th>Facturación</th>
            <th>Descuento</th>
            <th>Notas</th>
            <th>Tareas y citas</th>
            <th>Leads</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{fontWeight: 'bold'}}>
            <td>TOTAL</td>
            {plans.map((p, i) => (<td key={i}>{renderPlanStats(p, total_stats)}</td>))}
            <td>{renderInvoiceStats(total_stats)}</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>
          {dealers.map((d, i) => {
            const stats = subscriptions[d.billing && d.billing.customer_id];
            const saleleads =  metrics?.saleleads?.find(salelead => salelead._id === d._id)?.count;
            return (
              <tr key={i}>
                <td>{`${d.friendly_name || d.domain} (${d._id})`}</td>
                {plans.map((p, i) => (<td key={i}>{renderPlanStats(p, stats)}</td>))}
                <td>{renderInvoiceStats(stats)}</td>
                <td>{stats && renderDiscount(stats.discount)}</td>
                <td>{Math.floor(metrics?.notes?.find(note => note._id === d._id)?.count +
                  metrics?.conferences?.find(conference => conference._id === d._id)?.count / saleleads) || '-'}</td>
                <td>{Math.floor((metrics?.dates?.find(date => date._id === d._id)?.count +
                  metrics?.tasks?.find(task => task._id === d._id)?.count) / saleleads) || '-'}</td>
                <td>{saleleads || '-'}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  </div>)
}
