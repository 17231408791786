import BaseTable, {
  SortOrder,
}                from 'react-base-table';
import React, {
  useState,
  useEffect,
}                from 'react';
import { Link }  from 'react-router-dom';
import SearchBar from './SearchBar.js';
import 'react-base-table/styles.css';

const sortData = (data, sort_by, sort_order) => {
  const sortedData = data.sort((d1, d2) => {
    if (d1[sort_by] == null && d2[sort_by] != null)
      return 1;
    if (d1[sort_by] != null && d2[sort_by] == null)
      return -1;
    if (d1[sort_by] === d2[sort_by])
      return 0;
    if (sort_order === SortOrder.ASC)
      return d1[sort_by] - d2[sort_by];
    else
      return d2[sort_by] - d1[sort_by];
  });
  return sortedData;
};

const formatDate = (raw_date) => {
  if (!raw_date)
    return null;
  const date = new Date(raw_date);
  return Math.round((new Date() - date) / (1000 * 60 * 60 * 24));
};

const daysFormatter = ({ cellData }) => cellData == null ? '-' : `${cellData} días`;

const friendlyNameFormatter = ({ rowData, cellData }) => {
  const link = `/app/home?dealer_id=${rowData?.id}`;
  return (
    <Link to={link}>{cellData}</Link>
  );
};

const DATA_WIDTH = 200;
const columns = [
  {
    key: 'friendly_name',
    dataKey: 'friendly_name',
    title: 'Dealer',
    width: 300,
    cellRenderer: friendlyNameFormatter,
  },
  {
    key: 'last_status',
    dataKey: 'last_status',
    title: 'Ultimo cambio de estado',
    width: DATA_WIDTH,
    cellRenderer: daysFormatter,
    sortable: true,
  },
  {
    key: 'last_communication',
    dataKey: 'last_communication',
    title: 'Ultima comunicacion',
    width: DATA_WIDTH,
    cellRenderer: daysFormatter,
    sortable: true,
  },
  {
    key: 'last_lead',
    dataKey: 'last_lead',
    title: 'Ultimo lead',
    width: DATA_WIDTH,
    cellRenderer: daysFormatter,
    sortable: true,
  },
  {
    key: 'last_appraisal',
    dataKey: 'last_appraisal',
    title: 'Ultima tasacion',
    width: DATA_WIDTH,
    cellRenderer: daysFormatter,
    sortable: true,
  },
  {
    key: 'last_lead_import_task',
    dataKey: 'last_lead_import_task',
    title: 'Ultimo lead importado',
    width: DATA_WIDTH,
    cellRenderer: daysFormatter,
    sortable: true,
  },
  {
    key: 'last_won_lead',
    dataKey: 'last_won_lead',
    title: 'Ultimo lead ganado',
    width: DATA_WIDTH,
    cellRenderer: daysFormatter,
    sortable: true,
  },
  {
    key: 'user_count',
    dataKey: 'user_count',
    title: 'usuarios',
    width: DATA_WIDTH,
    sortable: true,
  },
  {
    key: 'user_mail_not_synced_count',
    dataKey: 'user_mail_not_synced_count',
    title: 'usuarios sin sincro (3+dias)',
    width: DATA_WIDTH,
    sortable: true,
  },
  {
    key: 'user_mail_enabled_count',
    dataKey: 'user_mail_enabled_count',
    title: 'Usuarios con sincro',
    width: DATA_WIDTH,
    sortable: true,
  },
];

const default_sort = {
  key: 'last_lead',
  order: SortOrder.DESC,
};

const CustomerSuccessTable = () => {
  const [data, setData] = useState([]);
  const [sort_by, setSortBy] = useState(default_sort);
  const [selected_dealer_name, setSelectedDealerName] = useState();

  useEffect(() => {
    fetch('/api/customer_data')
      .then(res => res.json())
      .then(dealers_data => {
        const processed_data = dealers_data.dealers.map(d => {
          return {
            ...d,
            last_lead: formatDate(d.last_lead),
            last_appraisal: formatDate(d.last_appraisal),
            last_communication: formatDate(d.last_communication),
            last_status: formatDate(d.last_status),
            last_lead_import_task: formatDate(d.last_lead_import_task),
            last_won_lead: formatDate(d.last_won_lead),
            user_count: d.user_count,
            user_mail_enabled_count: d.user_mail_enabled_count,
            user_mail_not_synced_count: d.user_mail_not_synced_count,
          };
        });
        setData(sortData(processed_data, default_sort.key, default_sort.order));
      })
      .catch(console.error);
  }, []);

  const onColumnSort = ({ key, order }) => {
    setSortBy({ key, order });
    const sorted_data = sortData(data, key, order);
    setData(sorted_data);
  };

  const regexp = new RegExp(selected_dealer_name, 'i');

  return (
    <div style={{ display: 'flex', gap: '1em', flexDirection: 'column', alignItems: 'left' }}>
      <SearchBar
        onChange={e => {
          setSelectedDealerName(e.target.value);
        }}
        dealer_name={selected_dealer_name}
      />
      <BaseTable
        columns={columns}
        data={data.filter(d => d.friendly_name && d.friendly_name.match(regexp))}
        width={1200}
        height={800}
        sortBy={sort_by}
        onColumnSort={onColumnSort}
      />
    </div>
  );
};
export default CustomerSuccessTable;
