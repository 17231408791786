import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const options = [
  {value: 'Twilio', label: 'Twilio'},
  {value: 'LoginAs', label: 'LoginAs'},
  {value: 'Master&Commander', label: 'Master&Commander'},
  {value: 'SystemStatus', label: 'Estado del sistema'},
  {value: 'Developer', label: 'Desarrollo'},
  {value: 'Billing', label: 'Facturación'},
  {value: 'Library', label: 'Library'},
];

const UserForm = ({ user, saveUser, resetPassword, handleClose }) => {
  const [name, setName] = useState();
  const [surname, setSurname] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [permissions, setPermissions] = useState();

  useEffect(() => {
    setName(user?.name ?? '');
    setSurname(user?.surname ?? '');
    setEmail(user?.email ?? '');
    setPhone(user?.phone ??  '');
    const perms = user? options.filter(option => user.permissions.includes(option.value)) : [];
    setPermissions(perms);
  }, [user]);

  const handleSubmit = (event) => {
    event.preventDefault();
    saveUser({
      name: name,
      surname: surname,
      email: email,
      phone: phone,
      permissions: permissions && permissions.map(p => p.value) || [],
      _id: user?._id ?? null,
    });
  };

  const handleResetPwd = (event) => {
    event.preventDefault();
    resetPassword(user);
  };

  const resetPwdButton = () => {
    if (user && user._id) return(
      <div>
        <button type='button' className='penguin-button' onClick={(e) => handleResetPwd(e)}>Restablecer contraseña</button>
      </div>
    );
    return null;
  };

  return(
    <form style={{'display': 'grid', 'gridTemplateColumns': 'auto 1fr', 'gap': '1em', 'alignItems': 'center', 'padding':'10px'}}>
      <label style={{'margin':0}}>
        Nombre
      </label>
      <input type='text' className='field' name='name' value={name} onChange={(e) => setName(e.target.value)}/>
      <label style={{'margin':0}}>
        Apellidos
      </label>
      <input type='text' className='field' name='surname' value={surname} onChange={(e) => setSurname(e.target.value)}/>
      <label style={{'margin':0}}>
        Email
      </label>
      <input type='text' className='field' name='email' value={email} onChange={(e) => setEmail(e.target.value)}/>
      <label style={{'margin':0}}>
        Teléfono
      </label>
      <input type='text' className='field' name='phone' value={phone} onChange={(e) => setPhone(e.target.value)}/>
      <label style={{'margin':0}}>
        Permisos
      </label>
      <Select
        className='select'
        name='permission-select'
        menuPortalTarget={document.getElementById('portal')}
        styles={{
          menuPortal: base => ({
            ...base,
            zIndex: 9999,
          }),
        }}
        options={options}
        value={permissions}
        onChange={setPermissions}
        closeMenuOnSelect='false'
        isMulti='true'
      />
      <div style={{display:'flex', justifyContent:'space-between', gridColumn:'1/-1'}}>
        {resetPwdButton()}
        <div>
          <button type='button' className='penguin-button' onClick={(e) => handleSubmit(e)}> Guardar </button>
          <div className='divider'/>
          <button className='penguin-button' style={{'background':'red'}} type='button' onClick={handleClose}>
            Cancelar
          </button>
        </div>
      </div>
    </form>
  )
}

export default UserForm;
