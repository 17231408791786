import React, {
  useState,
  useEffect,
}                          from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faTrashAlt,
  faUserPlus,
}                          from '@fortawesome/free-solid-svg-icons';
import Modal               from './components/Modal';
import UserForm            from './components/UserForm';

const UserFormModal = Modal(UserForm);

const UserRow = ({
  editUser,
  user,
  show_inactive,
  deleteUser,
  agent_id,
}) => {

  if (user.active || show_inactive) return (
    <tr>
      <td>{user.name}</td>
      <td>{user.surname}</td>
      <td>{user.email}</td>
      <td>{(user.permissions || []).map(permission => (<div key={permission}>{permission}</div>))}</td>
      <td>
        <button
          onClick={() => editUser(user)}
          className='icon-button'
          name={user._id}
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>
        <button
          className='icon-button'
          onClick={() => deleteUser(user)}
          name={user._id}
          disabled={agent_id === user._id}
        >
          <FontAwesomeIcon icon={user.active? faTrashAlt : faUserPlus}/>
        </button>
      </td>
    </tr>
  );
  return null;
}

const UserTable = ({
  users,
  editUser,
  deleteUser,
  agent_id,
  filter_text,
  show_inactive,
}) => {
  if (users === null) return null;
  const rows = users
    .filter(user => ['name', 'surname', 'email'].some(attribute =>
      user[attribute]?.toLowerCase().includes(filter_text.toLowerCase()),
    ))
    .map(user => (
      <UserRow
        user={user}
        key={`${user._id}`}
        editUser={editUser}
        deleteUser={deleteUser}
        agent_id={agent_id}
        show_inactive={show_inactive}
      />
    ));

  return (
    <table>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Apellido</th>
          <th>Email</th>
          <th>Permisos</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
}

const SearchBar = ({
  filter_text,
  onFilterTextChange,
}) => (
  <form>
    <input
      type='text'
      placeholder='Search...'
      value={filter_text}
      onChange={e => onFilterTextChange(e.target.value)}
    />
  </form>
);

const Admin = ({
  user,
}) => {

  const [users, setUsers] = useState(null);
  const [filter_text, setFilterText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userDetail, setUserDetail] = useState(null);
  const [show_inactive, setShowInactive] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(prev_state => !prev_state);
    if (isModalOpen) setUserDetail(null);
  }

  useEffect(() => {
    fetch('/api/users')
      .then(u => u.ok ?
        u.json()
        :
        u.text().then(t => Promise.reject(`Unexpected error code ${u.status} when fetching user data: ${t}`)))
      .then(u => setUsers(u))
      .catch(e => alert(e));
  }, []);

  const resetPassword = (user) => {
    fetch(`/api/users/${user._id}/resetpassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    }).then(u => u.ok ?
      u.json()
      :
      u.text().then(t => Promise.reject(`Unexpected error code ${u.status} while resetting user password: ${t}`)))
      .then(r => alert(`El código de activación del usuario es: ${r.otp}`))
      .catch(e => alert(e));
  }

  const updateUser = (user) => {
    fetch(`/api/users/${user._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    })
      .then(u => u.ok
        ? u.json()
        : u.text().then(t => Promise.reject(`Unexpected error code ${u.status} while ${user.active ? 'updating' : 'deactivating'} user: ${t}`)))
      .then(u => {
        const updatedUserArray = users.map(a => {
          if (a._id !== u.user._id) return a;
          return u.user;
        });
        setUsers(updatedUserArray);
      })
      .catch(e => alert(e));
  }

  const saveUser = (user) => {
    toggleModal();
    if (user._id) updateUser(user);
    else resetPassword(user);
  }

  const deleteUser = (user) => {
    user.active = !user.active;
    updateUser(user);
  }

  const editUser = (user) => {
    setUserDetail(user);
    toggleModal();
  }

  const handleFilterTextChange = (filter_text) => {
    setFilterText(filter_text);
  }

  const handleShowInactiveToggle = (event) => {
    setShowInactive(event.target.checked);
  }

  return (
    <div>
      <UserFormModal
        opened={isModalOpen}
        close={() => setIsModalOpen(false)}
        title={userDetail ? "Actualizar usuario" : "Crear usuario"}
        user={userDetail}
        saveUser={saveUser}
        resetPassword={resetPassword}
        handleClose={toggleModal}
      />
      <div style={{ margin: '1em' }}>
        <SearchBar
          style={{ margin: '1em' }}
          filter_text={filter_text}
          onFilterTextChange={handleFilterTextChange}
        />
      </div>
      <div style={{ margin: '1em' }}>
        <button type='button' className='button' onClick={toggleModal}> Nuevo usuario </button>
      </div>
      <div style={{ margin: '1em', display: 'flex', alignItems: 'center' }}>
        <label>Mostrar usuarios inactivos </label>
        <div className='divider' />
        <label className='switch'>
          <input
            name='show_inactive'
            type='checkbox'
            checked={show_inactive}
            onChange={handleShowInactiveToggle}
            className='slider-round'
          />
        </label>
      </div>
      <UserTable
        users={users}
        filter_text={filter_text}
        editUser={editUser}
        deleteUser={deleteUser}
        agent_id={user._id}
        show_inactive={show_inactive}
      />
    </div>
  );
}

export default Admin;
