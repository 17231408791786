import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
}             from 'react';
import {
  useQueryParam,
  StringParam,
}             from 'use-query-params';

import Select, {
  components,
}             from 'react-select';
import Toggle from '../../../../components/Toggle';

const sortUsers = users => users
  .sort((u1, u2) => `${u1.first_name}${u1.last_name}`.localeCompare(`${u2.first_name}${u2.last_name}`));

const Option = ({ children, ...props }) => {
  const user_id = props.data.value;
  const roles = props.selectProps.roles;
  const users = props.selectProps.users;
  const user = users.find(u => u._id === user_id);
  const user_role = user && roles.find(u => u._id === user.role);
  return (
    <div>
      <components.Option {...props}>
        {children} ({user_role && user_role.name})
      </components.Option>
    </div>
  )
}

const SingleValue = ({ children, ...props }) => {
  const user_id = props.data.value;
  const roles = props.selectProps.roles;
  const users = props.selectProps.users;
  const user = users.find(u => u._id === user_id);
  const user_role = user && roles.find(u => u._id === user.role);
  return (
    <div>
      <components.SingleValue {...props}>
        {children} ({user_role && user_role.name})
      </components.SingleValue>
    </div>
  )
}

const patchDebugMode = (dealer_id, user_id, activate) => fetch(`/api/mapi/dealers/${dealer_id}/users/${user_id}`, {
  method: 'PATCH',
  headers: {'Content-Type': 'application/json'},
  body: JSON.stringify([{op: 'replace', path: `/debug_mode`, value: activate}]),
})
  .then(r => r.ok && r.json() || r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`)))

export default ({ dealer_id }) => {
  const [ selected_user, setSelectedUser ] = useQueryParam('user_id', StringParam);
  const [ debug_mode, setDebugMode ] = useState(false);
  const [ users, setUsers ] = useState({options: [], users: []});
  const [ roles, setRoles ] = useState([]);
  const loginButtonRef = useRef(null);

  const doLoginAs = user_id => {
    fetch(`/api/login_as/${dealer_id}/${user_id}`)
      .then(res => res.ok ? res.json() : res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`)))
      .then(decoded_token => {
        window.open(`${process.env.REACT_APP_MAPP_WEB_CLIENT_BASE_URL}autologin?decoded_token=${encodeURIComponent(JSON.stringify(decoded_token))}`)
      })
      .catch(err => alert(err.toString()))
  }
  const loadAllUsers = useCallback(async () => {
    const all_users = [];
    let has_more = false, skip = 0;
    do {
      const params = new URLSearchParams({
        q: JSON.stringify({active: true}),
        skip,
        limit: 50,
        sort: '-_id',
        project: '_id=1,first_name=1,last_name=1,role=1,plans=1,debug_mode=1',
      });
      const [r, next_has_more] = await fetch(`/api/mapi/dealers/${dealer_id}/users?${params}`)
        .then(res => res.ok ? res.json().then(d => [d, res.headers.get('mapp-has-more') === 'true']) : res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`)));
      skip += r.length;
      has_more = next_has_more;
      all_users.push(...r);
    } while (has_more);

    return all_users;
  }, [ dealer_id ]);

  useEffect(() => {
    fetch(`/api/mapi/dealers/${dealer_id}/roles`)
      .then(res => res.ok ? res.json() : res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`)))
      .then(roles => setRoles(roles));
  }, [ dealer_id]);

  useEffect(() => {
    if (loginButtonRef.current)
      loginButtonRef.current.focus();
  }, [ dealer_id, selected_user ]);

  useEffect(() => {
    if (selected_user && users?.users?.length > 0)
      setDebugMode(users.users.find(u => u._id === selected_user)?.debug_mode);
  }, [ users, selected_user ]);

  useEffect(() => {
    if (!roles.length) return;
    loadAllUsers().then(users => {
      const adminIds = roles
        .filter(role => role.active_permission_groups.includes('super_admin'))
        .map(role => role._id);
      setSelectedUser(
        adminIds && adminIds[0] &&
        users.filter(u => adminIds.includes(u.role)  && u.plans.includes('base')).map(f => f._id).find(f => f) ||
        users[0]._id)
      const user_options = sortUsers(users).map(d => ({label: `${d.role === 'dealer_admin' ? '* ' : ''}${d.first_name} ${d.last_name}`, value: d._id}));
      setUsers({
        users,
        options: user_options,
      });
    });
  }, [ loadAllUsers, setSelectedUser, roles ])

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    }}>
      {dealer_id && users && (<div style={{
        display: 'flex',
        alignItems: 'center',
        gap: '1em',
      }}>
        <Select
          styles={{
            container: base => ({...base, width: '30em'}),
          }}
          onChange={e => {
            if (loginButtonRef.current) loginButtonRef.current.focus();
            setSelectedUser(e.value);
          }}
          options={users.options}
          key={dealer_id}
          roles={roles}
          users={users.users}
          components={{SingleValue, Option}}
          value={users.options.find(u => u.value === selected_user)}
        />
        {selected_user && <div style={{display: 'flex', alignItems: 'center', gap: '0.5em'}}>
          <span>Reporte de errores</span>
          <Toggle
            checked={debug_mode}
            onChange={() => patchDebugMode(dealer_id, selected_user, !debug_mode)
             .then(() => setDebugMode(!debug_mode))
             .catch(err => {alert(`Error ${!debug_mode ? 'activando' : 'desactivando'} reporte de errores: `.concat(err.toString())); Promise.reject(err.toString());})
            }
          />
        </div>}
      </div>)}
      {dealer_id && selected_user && (
        <button ref={loginButtonRef} style={{marginTop: '1em', padding: '1em'}} onClick={() => doLoginAs(selected_user)}>
          Entrar
        </button>
      )}
    </div>
  );
}
