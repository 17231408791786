import React, {
  useState,
  useEffect,
} from 'react';
import Select from 'react-select';
import moment from 'moment';
import 'moment/locale/es';
import Modal from '../Admin/components/Modal'

moment.locale('es');

const lang_options = [
  {value: 'es-ES', label: 'Español (España)'},
  {value: 'es-MX', label: 'Español (México)'},
  {value: 'ca-ES', label: 'Catalán (España)'},
  {value: 'en-US', label: 'Inglés (EE.UU.)'},
  {value: 'en-GB', label: 'Inglés (UK)'},
  {value: 'fr-FR', label: 'Frances (Francia)'},
  {value: 'it-IT', label: 'Italiano (Italia)'},
  {value: 'nl-NL', label: 'Neerlandés (Paises Bajos)'},
];

const currency_options = [
  {value: 'EUR', label: 'Euro'},
  {value: 'USD', label: 'Dólar Estadounidense'},
  {value: 'GBP', label: 'Libra Esterlina'},
];

const country_options = [
  {value: 'ES', label: 'España'},
  {value: 'MX', label: 'México'},
  {value: 'PE', label: 'Perú'},
  {value: 'PR', label: 'Puerto Rico'},
  {value: 'CO', label: 'Colombia'},
  {value: 'CL', label: 'Chile'},
  {value: 'FR', label: 'Francia'},
  {value: 'IT', label: 'Italia'},
  {value: 'NL', label: 'Países Bajos'},
  {value: 'BE', label: 'Bélgica'},
  {value: 'GB', label: 'Reino Unido'},
];

const tax_rate_options = [
  {label: 'IVA (21%)', value: 'ES_IVA'},
  {label: 'Sin impuestos', value: null},
];


const product_options = [
  {value: 'WALCUCRM', label: 'Walcu CRM'},
  {value: 'WALCUCRMUSD', label: 'Walcu CRM (Cobrado en dólares)'},
  {value: 'WALCUCRMGBP', label: 'Walcu CRM (Cobrado en libras)'},
];


const select_styles = {menuPortal: base => ({...base, zIndex: 9999})};

const products_per_currency = {
  EUR: ['WALCUCRM'],
  USD: ['WALCUCRMUSD'],
  GBP: ['WALCUCRMGBP'],
}


const DealerInfo = ({ createDealer }) => {
  const [ lang, setLang ] = useState();
  const [ country_code, setCountryCode ] = useState();
  const [ currency, setCurrency ] = useState();
  const [ product, setProduct ] = useState();
  const [ tax_rate, setTaxRate ] = useState();
  const [ error, setError ] = useState(null);
  const [ success, setSuccess ] = useState(false);
  const [ creating_dealer, setCreatingDealer ] = useState(false);
  if(creating_dealer) return "Creando la cuenta...";
  else if(success) return "La cuenta ha sido creada con éxito";
  else if(error) return `Ha ocurrido un error al crear la cuenta: ${error}`;
  else return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '1em'}}>
      <b>Aviso: Revisa bien esta configuración. Una vez creada la cuenta no se puede cambiar</b>
      <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', alignItems: 'center', gap: '1em' }}>
        <span>Idioma:</span>
        <Select
          className='select'
          name='permission-select'
          menuPortalTarget={document.getElementById('portal')}
          styles={select_styles}
          options={lang_options}
          value={lang_options.find(l => l.value === lang)}
          onChange={o => setLang(o?.value)}
        />
        <span>País:</span>
        <Select
          className='select'
          name='permission-select'
          menuPortalTarget={document.getElementById('portal')}
          styles={select_styles}
          options={country_options}
          value={country_options.find(l => l.value === country_code)}
          onChange={o => setCountryCode(o?.value)}
        />
        <span>Moneda:</span>
        <Select
          className='select'
          name='permission-select'
          menuPortalTarget={document.getElementById('portal')}
          styles={select_styles}
          options={currency_options}
          value={currency_options.find(l => l.value === currency)}
          onChange={o => { setCurrency(o?.value); setProduct(products_per_currency[o?.value][0]) }}
        />
        <span>Producto:</span>
        <Select
          className='select'
          name='permission-select'
          menuPortalTarget={document.getElementById('portal')}
          styles={select_styles}
          options={product_options.filter(o => products_per_currency[currency]?.includes(o.value))}
          value={product_options.find(l => l.value === product)}
          onChange={o => setProduct(o?.value)}
        />
        <span>Tipo de impuesto:</span>
        <Select
          className='select'
          name='permission-select'
          menuPortalTarget={document.getElementById('portal')}
          styles={select_styles}
          options={tax_rate_options}
          value={tax_rate_options.find(l => l.value === tax_rate)}
          onChange={o => setTaxRate(o?.value)}
        />
      </div>
      <button disabled={!(lang && country_code && currency && product)} onClick={() => {
        setCreatingDealer(true);
        createDealer({lang, country_code, currency, product, tax_rate}).then(() => {
          setCreatingDealer(false);
          setSuccess(true);
        }).catch(err => {
          setCreatingDealer(false);
          setError(err);
        })
      }}>Crear cuenta</button>
    </div>
  );
}

const DealerInfoModal = Modal(DealerInfo);

const loadRequests = () => fetch(`/api/register_requests`)
  .then(r => r.ok && r.json() || r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`)))

export default () => {
  const [ requests, setRequests ] = useState([]);
  const [ creating_dealer, setCreatingDealer ] = useState(null);
  useEffect(() => { loadRequests().then(setRequests) }, []);
  const acceptRequest = (request, extra_data) => fetch(`/api/register_requests/${request._id}/accept`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(extra_data),
  })
    .then(res => res.ok && res.json() || res.text().then(t => Promise.reject(`Unexpected status code ${res.status}: ${t}`)))
    .then(loadRequests)
    .then(setRequests);
  const rejectRequest = request => fetch(`/api/register_requests/${request._id}/reject`, {method: 'POST'})
    .then(r => r.json())
    .then(r => r.ok ? r : (window.alert(`Error: ${r.message}`) || r))
    .then(loadRequests)
    .then(setRequests);
  return (
    <div style={{width: '100%', padding: '1em'}}>
      <DealerInfoModal
        title="Aceptar petición de registro"
        creating_dealer={creating_dealer}
        opened={Boolean(creating_dealer)}
        close={() => setCreatingDealer(null)}
        createDealer={dealer_data => acceptRequest(creating_dealer, dealer_data)}
      />
      <table style={{width: '100%'}}>
        <thead>
          <tr>
            <th> Dominio </th>
            <th> Email </th>
            <th> Nombre </th>
            <th> Teléfono </th>
            <th> Acciones </th>
          </tr>
        </thead>
        <tbody>
          {requests.map(r => (
            <tr key={r._id}>
              <td> {r.domain} </td>
              <td> {r.email} </td>
              <td> {r.first_name} {r.last_name} </td>
              <td> {r.phone} </td>
              <td>
                <button onClick={() => setCreatingDealer(r)}>Aceptar</button>
                <button onClick={() => rejectRequest(r)}>Rechazar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
