import React, {
  useState,
} from 'react';
import StyledButton from '../StyledButton';

const AsyncButton = props => {
  const {
    onClick,
    disabled,
    loading_text,
    ready: propsReady,
    onReadyChange,
    button_ref,
  } = props;
  const [ state_ready, setStateReady ] = useState(true);
  const controlled = propsReady != undefined;
  const ready =  controlled ? propsReady : state_ready;
  const setReady = controlled ? onReadyChange : r => setStateReady(r);
  return (
    <StyledButton
      ref={button_ref}
      type='text'
      {...props}
      disabled={disabled || !ready}
      onClick={e => {
        setReady(false)
        Promise.resolve(onClick(e))
          .then(() => setReady(true))
          .catch(() => setReady(true));
      }}
    >
      {ready ? props.children : loading_text || props.children}
    </StyledButton>
  );
}

export default AsyncButton;
