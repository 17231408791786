import React from 'react'

import Async from 'react-select/async';

const loadAddresses = dealer_id => async a =>
  fetch(`/api/mappex/services/twilio/dealers/${dealer_id}/addresses`)
    .then(r => r.ok && r.json() || r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`)))
    .then(addresses => addresses
      .filter(add => add.friendlyName.includes(a))
      .map(addr => ({
        value: addr.sid,
        label: addr.friendlyName,
      })),
    );

export default ({
  onChange,
  dealer_id,
}) => {
  return (
    <Async
      styles={{
        container: base => ({...base, flex: 1}),
      }}
      onChange={e => onChange(e.value)}
      cacheOptions
      loadOptions={loadAddresses(dealer_id)}
      defaultOptions
    />
  );
}
