import CustomerSuccessTable from './components/CustomerSuccessTable.js';

const CustomerSuccess = () => {
  document.title="Customer Success";
  return (
    <>
      <h1>Customer success</h1>
      <CustomerSuccessTable />
    </>
  );
};

export default CustomerSuccess;
