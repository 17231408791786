import React, {
  useState,
}                from 'react';
import moment    from 'moment';
import 'moment/locale/es';

moment.locale('es');

const buttonStyle = {
  padding: '0.5em',
  marginTop: '1em',
};

function download(filename, blob) {
  const element = document.createElement('a');
  const url = URL.createObjectURL(blob);
  element.setAttribute('href', url);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const getMessageById = message_id => fetch(`/api/mapi/emails?q=${encodeURIComponent(JSON.stringify({message_id}))}`)
  .then(r => r.ok && r.json() || r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`)))
  .then(r => r && r[0] && r[0]._id)

const getDealerId = email_id => fetch(`/api/mapi/emails/${email_id}`)
  .then(r => r.ok && r.json() || r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`)))
  .then(r => r && r[0] && r[0].dealer_id)


const getEml = (dealer_id, email_id) => fetch(`/api/mappex/services/mail/incoming/dealers/${dealer_id}/emails/${email_id}/eml`).then(r => r.ok && r.blob() || r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`)))


const downloadEml = async (messageId, crmId) => {

  const crm_id = crmId || await getMessageById(messageId);
  const dealer_id = await getDealerId(crm_id);
  const eml = await getEml(dealer_id, crm_id);
  download(`${crm_id}.eml`, eml);
}

export default () => {
  const [ messageId, setMessageId ] = useState("");
  const [ crmId, setCrmId ] = useState("");
  return (
    <>
      <div>
        <input value={messageId} onChange={e => setMessageId(e.target.value)} placeholder={'Message-ID'}/>
      </div>
      <div>
        <input value={crmId} onChange={e => setCrmId(e.target.value)} placeholder={'ID CRM'}/>
      </div>
      <button style={buttonStyle} onClick={() => downloadEml(messageId, crmId)}>Descargar .eml</button>
    </>
  )
}
