import React, {
  useState,
  useCallback,
  useEffect,
}                          from 'react';
import { faDownload }      from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Library = () => {
  const [ packages, setPackages ] = useState([]);
  const [ uploading_files, setUploadingFiles ] = useState(null);

  const refreshPackages = useCallback(() => {
    fetch(`/api/library/admin/packages`)
      .then(res => res.ok ? res.json() : res.text().then(t => Promise.reject(`Unexpected ${res.status} at ${res.url}: ${t}`)))
      .then(setPackages)
      .catch(err => alert(err.toString()));
  }, []);

  useEffect(() => { refreshPackages() }, [ refreshPackages ]);

  const deletePackage = package_id => {
    fetch(`/api/library/admin/packages/${package_id}`, { method: 'DELETE' })
      .then(res => res.ok ? res.json() : res.text().then(t => Promise.reject(`Unexpected ${res.status} at ${res.url}: ${t}`)))
      .then(refreshPackages)
      .catch(err => alert(err.toString()));
  }

  const uploadPackage = async () => {
    for (const uploading_file of uploading_files) {
      await fetch(`/api/library/admin/packages/${uploading_file.name}`, {
        method: 'POST',
        body: uploading_file,
      })
        .then(res => res.ok ? res.json() : res.text().then(t => Promise.reject(`Unexpected ${res.status} at ${res.url}: ${t}`)))
        .then(refreshPackages)
        .catch(err => alert(err.toString()));
    }
  }

  return (
    <div style={{padding: '1em'}}>
      <h1>Library</h1>
      <hr/>
      <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(30em, 1fr))', flexDirection: 'column', gap: '1em'}}>
        {packages.map(p => (
          <div style={{
            display: 'flex',
            gap: '1em',
            padding: '1em',
            alignItems: 'center',
            borderRadius: '6px',
            backgroundColor: 'var(--background-light-2)',
          }} id={p._id}>
            <div>
              {p.filename}
            </div>
            <div>
              <a
                target="_blank"
                href={`${process.env.REACT_APP_MAPP_WEB_CLIENT_BASE_URL}crm/install/${p.filename}`}
              >
                Enlace de instalación
              </a>
            </div>
            <div style={{flex: 1}}/>
            <a href={`${process.env.REACT_APP_WALCU_LIBRARY_BASE_URL}packages/${p.filename}`}>
              <FontAwesomeIcon icon={faDownload}/>
            </a>
            <button onClick={() => deletePackage(p._id)}>
              Borrar
            </button>
          </div>
        )).concat(
          <div style={{
            display: 'flex',
            gap: '1em',
            padding: '1em',
            alignItems: 'center',
            borderRadius: '6px',
            border: `1px dashed var(--content-4)`,
            backgroundColor: 'var(--background-light-2)',
          }}>
            Subir nuevo paquete
            <input type='file' multiple accept="application/zip" onChange={e => setUploadingFiles(e.target.files)}/>
            <div style={{flex: 1}}/>
            {Boolean(uploading_files?.length) && (
              <button onClick={uploadPackage}>
                Subir
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Library;
