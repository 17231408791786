import React, {
  useState,
  useEffect,
}                    from 'react';
import ReactDOM      from 'react-dom';
import { usePopper } from 'react-popper';

export default ({
  TagComponent: props_TagComponent,
  component_style,
  children,
  content,
  display_content,
  placement,
  offset,
  portal_element,
}) => {
  const TagComponent = props_TagComponent || 'div';
  // If we use useRef instead of useState the Popup wont be positioned correctly
  const [ button_ref, setButtonRef ] = useState(null);
  const [ popup_ref, setPopupRef ] = useState(null);
  const [ arrow_ref, setArrowRef ] = useState(null);
  const { styles, update } = usePopper(button_ref, popup_ref, {
    modifiers: [
      { name: 'arrow', options: { element: arrow_ref } },
      { name: 'offset', options: { offset: offset || [0, 0] } },
    ],
    placement: placement || 'bottom',
    strategy: 'absolute',
  });
  useEffect(() => {
    // Needed because Popper won't display the popup in the correct position otherwise,
    // until you scroll/move around. This is because the element being drawn (display to true)
    // doesn't fire popper functions by default
    if (update && display_content) update();
  }, [update, display_content]);

  const popper_content = (
    <div ref={setPopupRef} style={{
      zIndex: 6,
      filter: display_content ? 'opacity(1)' : 'opacity(0.5)',
      transition: 'filter 0.15s ease-in-out',
      ...styles.popper,
    }}>
      {display_content && content}
      <div ref={setArrowRef} style={styles.arrow} />
    </div>
  );

  return (
    <>
      <TagComponent style={component_style} ref={setButtonRef}>{children}</TagComponent>
      {portal_element ? ReactDOM.createPortal(popper_content, portal_element) : popper_content}
    </>
  );
};
