import React         from 'react';
import EmlDownloader from './components/EmlDownloader';
import EmailSync     from './components/EmailSync';
import 'moment/locale/es';

export default () => {
  return (
    <div style={{width: '100%', padding: '1em'}}>
      <h1> Descargar .eml </h1>
      <EmlDownloader/>
      <br/> <br/>
      <h1> Sincronización de emails </h1>
      <EmailSync/>
    </div>
  )
}
