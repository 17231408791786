import React, {
  useState,
  useEffect,
  useMemo,
}                          from 'react';
import {
  useQueryParam,
  StringParam,
}                          from 'use-query-params';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
}                          from '@fortawesome/free-solid-svg-icons';

const getHeaders = () => ([{
  label: 'Marca',
  width: '30%',
  renderCell: s => s.request?.make,
}, {
  label: 'Modelo',
  width: '30%',
  renderCell: s => s.request?.model,
}, {
  label: 'Versión',
  width: '40%',
  renderCell: s => s.request?.version,
}, {
  label: 'Año',
  width: '10%',
  renderCell: s => s.request?.year,
}, {
  label: 'Combustible',
  width: '20%',
  renderCell: s => s.request?.fuel,
}, {
  label: 'Puertas',
  width: '15%',
  renderCell: s => s.request?.doors,
}, {
  label: 'Transmisión',
  width: '20%',
  renderCell: s => s.request?.transmission,
}, {
  label: 'Kilometraje',
  width: '20%',
  renderCell: s => s.request?.mileage,
}, {
  label: 'Potencia',
  width: '15%',
  renderCell: s => s.request?.horse_power || s.request?.horsePower,
}, {
  label: 'Condición',
  width: '18%',
  renderCell: s => s.request?.condition,
}, {
  label: 'Valor de venta',
  width: '15%',
  renderCell: s => s.response?.sale_value,
}, {
  label: 'Valor de compra',
  width: '15%',
  renderCell: s => s.response?.appraisal_value,
}, {
  label: 'Confianza',
  width: '18%',
  renderCell: s => s.response?.confidence,
}, {
  label: 'Fecha',
  width: '40%',
  renderCell: s => new Date(s.created_at).toLocaleString('es-ES'),
}])

const AppraisalsTable = ({
  appraisals,
}) => {
  const headers = useMemo(() => getHeaders(), []);
  return (
    <div style={{overflow: 'auto'}}>
      <table style={{ width: '100%', alignSelf: 'center', tableLayout: 'fixed' }}>
        <thead>
          <tr>
            {headers.map(({label, width}) => (
              <th style={{ width }}>
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {appraisals.map(s => (
            <tr>
              {headers.map(({renderCell}) => (
                <td style={{fontSize: '16px', wordBreak: 'break-word'}}>
                  {renderCell(s)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const Paginator = ({
  page,
  onChange,
  has_more,
}) => {
  return (
    <div style={{display: 'flex', justifyContent: 'center', gap: '2px'}}>
      <button style={{marginTop: '1em', padding: '0.5em'}} disabled={page === 0} onClick={() => onChange(page - 1)}>
        <FontAwesomeIcon icon={faAngleDoubleLeft} size="2x" />
      </button>
      <div style={{fontWeight: '500', fontSize: '2em', alignSelf: 'center', marginTop: '1rem', padding: '0em 0.25em'}}>
        {page}
      </div>
      <button style={{marginTop: '1em', padding: '0.5em'}} disabled={!has_more} onClick={() => onChange(page + 1)}>
        <FontAwesomeIcon icon={faAngleDoubleRight} size="2x" />
      </button>
    </div>
  );
}

const fetchAppraisals = (user_id, skip, limit) => fetch(`/api/tasator/appraisals?user_id=${user_id}&skip=${skip}&limit=${limit}`)
  .then(res => res.ok ? res.json() : res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`)))
  .then(({appraisals}) => appraisals);

const AppraisalsView = ({ user_id }) => {
  const [ appraisals, setAppraisals ] = useState([]);
  const [ page = 0, setPage ] = useQueryParam('page', StringParam);

  useEffect(() => {
    setAppraisals([]);
    fetchAppraisals(user_id, page * 10, 10).then(setAppraisals)
  }, [user_id, page]);

  return (
    <div style={{margin: '1em'}}>
      <h2>Tasaciones</h2>
      <AppraisalsTable appraisals={appraisals}/>
      <Paginator page={Number(page)} has_more={appraisals.length == 10} onChange={setPage}/>
    </div>
  );
}

export default AppraisalsView;
